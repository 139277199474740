import React from 'react'
import BlockContent from '../block-content'
import PackageCtaButton from "./packageCtaButton"
import PackageSelect from "./packageSelect"
import tw from "tailwind.macro"
import { buildImageObj } from '../../lib/helpers'
import { imageUrlFor } from '../../lib/image-url'
// import Container from '../container'

// import styles from '../project.module.css'

function Packages (props) {
  // const {about_us} = props
  const FullWidthContainer =  tw.div`
    w-full flex flex-col items-center
  `
  const PageWidthContainer = tw.div`
    text-black max-w-7xl w-full font-display px-4
  `

  return (
    <>
    {props._rawPackages &&
         <FullWidthContainer className={"" + (props._rawPackagesSectionHeader ? 'py-10 bg-hr-quartz' : 'py-2 bg-white')}>
          <PageWidthContainer id="packages">
            {props._rawPackagesSectionHeader &&
            <div className="pb-2">
              <BlockContent blocks={props._rawPackagesSectionHeader || []} />
            </div>
            }
          <div className="md:flex flex-wrap mt-2" >

            {props._rawPackages.map((packageItem, index) => {
              return (
                <div key={`${index}_key`} className= {"flex md:pr-4 mb-6 " + ((index === 0) ? 'md:flex-1' : 'md:w-1/2')}>
                  <div className="flex-1 shadow-mdFull bg-white">
                    <div className="package-card-title">
                      {packageItem.packageLogo && packageItem.packageLogo.asset &&  (
                        <img width="40" style={{"display": "inline", "margin-right": "20px"}} className="inline pr-4"
                          src={imageUrlFor(buildImageObj(packageItem.packageLogo)).url()}
                        />
                      )}
                      {packageItem.title}
                    </div>
                    <div className="package-card-contents">
                      {packageItem.packageDetails &&
                        <div className="mb-6">
                          <BlockContent blocks={packageItem.packageDetails || []} />
                        </div>
                      }

                      {packageItem.packageType == 'ctaDropdown' &&
                        <PackageSelect
                          packageItem = {packageItem}
                          buttonText= {packageItem.CTAbuttonText}
                        />
                      }
                      {packageItem.packageType == 'ctaButton'  &&
                        <PackageCtaButton
                          packageItem = {packageItem}
                          buttonType={packageItem.CTAbuttonType}
                          buttonText= {packageItem.CTAbuttonText}
                          packageId = {packageItem.packageId}
                          packageCustomURL = {packageItem.packageCustomId}
                        />
                      }

                    </div>
                  </div>
                </div>
              )})}
          </div>
          </PageWidthContainer>
          </FullWidthContainer>
        }
        </>
  )
}

export default Packages